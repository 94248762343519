import './scss/plugins/_swiper.scss'
import Swiper from 'swiper'
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules'

// responsive breakpoints 	sm: 640px, md: 768px, lg: 1024px, xl: 1280px, xxl: 1536px

// change swiper controls color depending on color of current active slide
function changeControlsColor(swiperInstance) {
	const heroControls = swiperInstance.querySelector('.swiper-controls')
	const activeSlide = swiperInstance.querySelector('.swiper-slide-active')
	const controlsClass = 'color-text-white'

	activeSlide.classList.contains(controlsClass)
		? heroControls.classList.add(controlsClass)
		: heroControls.classList.remove(controlsClass)
}

const heroSwipers = document.querySelectorAll('[data-swiper-hero]')

heroSwipers.forEach(heroSwiper => {
	const heroAutoplay = heroSwiper.getAttribute('data-swiper-autoplay')

	const hero = new Swiper(heroSwiper, {
		modules: [Navigation, Pagination, EffectFade, Autoplay],
		effect: 'fade',
		autoplay: heroAutoplay
			? {
					delay: parseInt(heroAutoplay) * 1000,
					disableOnInteraction: true,
			  }
			: false,
		fadeEffect: {
			crossFade: true,
		},
		pagination: {
			el: '[data-swiper-pagination]',
			type: 'fraction',
		},
		navigation: {
			nextEl: '[data-swiper-button-next]',
			prevEl: '[data-swiper-button-prev]',
		},
		breakpoints: {
			1280: {
				speed: 1000,
			},
		},
		on: {
			init: function () {
				changeControlsColor(this.el)
			},
			slideChangeTransitionStart: function () {
				changeControlsColor(this.el)
			},
		},
	})
})

const sliderGalleries = document.querySelectorAll('[data-swiper-gallery]')
if (sliderGalleries.length) {
	sliderGalleries.forEach(gallery => {
		const slidesPerView = Number(gallery.getAttribute('data-slides-per-view'))
		let slidesPerViewTablet = 1
		if (slidesPerView >= 3) {
			slidesPerViewTablet = Math.round(slidesPerView / 2)
		}
		const slidesPerViewMobile = slidesPerView > 4 ? 2 : 1
		const sliderGallery = new Swiper(gallery, {
			modules: [Navigation, Pagination],
			slidesPerView: slidesPerViewMobile,
			spaceBetween: 14,
			autoHeight: true,
			loop: true,
			pagination: {
				el: '[data-swiper-pagination]',
				type: 'fraction',
			},
			navigation: {
				nextEl: '[data-swiper-button-next]',
				prevEl: '[data-swiper-button-prev]',
			},
			breakpoints: {
				640: {
					slidesPerView: slidesPerViewTablet,
				},
				1024: {
					slidesPerView: slidesPerView,
				},
			},
		})
	})
}

const ticker = new Swiper('[data-swiper-ticker]', {
	modules: [Autoplay],
	spaceBetween: 20,
	speed: 2000,
	autoplay: {
		delay: 0,
		disableOnInteraction: false,
	},

	grabCursor: true,
	loop: true,
	slidesPerView: 2,
	breakpoints: {
		640: {
			slidesPerView: 4,
		},
		1536: {
			slidesPerView: 7,
		},
	},
})
